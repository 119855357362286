import { useAuth0 } from '@auth0/auth0-react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/utils/Layout/Layout.component';
import Meta from '../components/utils/Meta/Meta.util';
import { AuthContext } from '../contexts/Auth.context';
import { StoryblokEntry } from '../interfaces/storyblok/StoryblokEntry.interface';
import { getSingleBlokItem } from '../utils/getSingleBlokItem/getSingleBlokItem.util';
import renderBloks from '../utils/renderBlocks/renderBloks';
import DynamicComponent from '../components/utils/DynamicComponent/DynamicComponent.component';
import getMetaData from '../utils/getMeta/GetMeta.util';
import { SiteLanguageContext } from '../contexts/SiteLanguage.context';
import BreadcrumbProps from '../interfaces/Breadcrumb/Breadcrumb.interface';
import getRequiredHeaderBlok from '../utils/getRequiredHeaderBlok/getRequiredHeaderBlok.util';
import getRequiredFooterBlok from '../utils/getRequiredFooterBlok/getRequiredFooterBlok.util';

interface StoryblokEntryWithFilterDataProps {
  storyblokEntry: StoryblokEntry;
}

interface PageContext {
  breadcrumb: BreadcrumbProps;
}

const StoryblokEntryWithFilterTemplate: React.FC<
  PageProps<StoryblokEntryWithFilterDataProps>
> = ({ data, location, pageContext, path }) => {
  const { user } = useAuth0();
  const { breadcrumb } = pageContext as any;

  const content = data?.storyblokEntry.content;
  const parsedContent = JSON.parse(content);

  const top_body_blocks = renderBloks(parsedContent?.top_body);
  const filter = getSingleBlokItem(parsedContent?.filter);
  const bottom_body_blocks = renderBloks(parsedContent?.bottom_body);

  const CANONICAL_URL = parsedContent?.canonical_url || '';
  const NO_INDEX = parsedContent?.no_index || '';
  const META_DATA = getMetaData(data?.storyblokEntry.content);
  const FULL_SLUG = data?.storyblokEntry.full_slug;
  const URL_PARTS = FULL_SLUG.split('/');
  const ALTERNATIVE_VERSIONS = data?.storyblokEntry.alternates;
  const LANG_PREFIX = (URL_PARTS?.length >= 1 && URL_PARTS[0]) || '';

  const PAGE_SLUG = FULL_SLUG.replace(`${LANG_PREFIX}/`, '').replace(
    `${process.env.DOMAIN_FOLDER}`,
    ''
  );
  const isAlternativeNav = PAGE_SLUG === '/';

  const headerBlok = getRequiredHeaderBlok('header', LANG_PREFIX);
  const footerBlok = getRequiredFooterBlok('footer', LANG_PREFIX);

  const HEADER = headerBlok && (
    <DynamicComponent blok={JSON.parse(headerBlok.content)} />
  );
  const FOOTER = footerBlok && (
    <DynamicComponent blok={JSON.parse(footerBlok.content)} />
  );

  return (
    <AuthContext.Provider value={{ user }}>
      <Layout
        langPrefix={LANG_PREFIX}
        isAlternativeNav={isAlternativeNav}
        breadcrumb={breadcrumb}
        header={HEADER}
        footer={FOOTER}
      >
        {META_DATA && (
          <Meta
            metaData={META_DATA}
            url={location.href}
            canonical={CANONICAL_URL}
            noIndex={NO_INDEX}
            alternatives={ALTERNATIVE_VERSIONS}
            pageSlug={PAGE_SLUG}
            langPrefix={LANG_PREFIX}
            breadcrumb={breadcrumb}
          />
        )}
        {top_body_blocks}
        {filter && (
          <SiteLanguageContext.Provider
            value={{ currentSiteLanguageSlug: LANG_PREFIX }}
          >
            <DynamicComponent blok={filter} key={filter._uid} />
          </SiteLanguageContext.Provider>
        )}
        {bottom_body_blocks}
      </Layout>
    </AuthContext.Provider>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      name
      full_slug
      content
      alternates {
        id
        full_slug
      }
    }
  }
`;

export default StoryblokEntryWithFilterTemplate;
